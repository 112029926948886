<template>
  <div>
    <b-modal
      id="intro-video-modal"
      centered
      size="md"
      :static="true"
      :lazy="false"
    >
      <template #modal-header="{ close }">
        <div class="d-flex justify-content-end w-100">
          <img
            src="@/assets/images/close.svg"
            alt="close"
            width="auto"
            height="auto"
            class="c-pointer"
            @click="close()"
          />
        </div>
      </template>
      <div class="cd-enroll__div cd-main">
        <b-row class="h-100 d-flex align-items-center m-0">
          <b-col md="12" class="h-100 p-0">
            <iframe
              width="100%"
              height="350"
              frameBorder="0"
              :src="getSelectedCourse.intro_video_url"
              title="Course Enroll"
            ></iframe>
          </b-col>
        </b-row>
      </div>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>

    <b-modal
      id="course-details-modal"
      centered
      size="md"
      :static="true"
      :lazy="false"
    >
      <template #modal-header>
        <div class="cd__header-text">
          {{ $t("general.course_details") }}
          <div
            class="close-icon--styles"
            @click="closeModal('course-details-modal')"
          >
            <img src="@/assets/images/close.svg" alt="close" />
          </div>
        </div>
      </template>
      <div class="cm__body p-0">
        <div class="cm__body--content">
          <div class="d-flex">
            <div class="cd__model-icon">
              <img
                src="@/assets/images/landing/course/info/modules.svg"
                alt="modules"
              />
            </div>
            <div class="cd__model-text">
              {{
                getSelectedCourse.sections?.length > 1
                  ? getSelectedCourse.sections?.length
                  : ""
              }}
              {{
                getSelectedCourse.sections?.length > 1
                  ? $t("search.tab.modules")
                  : $t("course.x_modules_one")
              }}
            </div>
          </div>
          <!-- <div class="d-flex">
            <div class="cd__model-icon">
              <img src="@/assets/images/landing/course/info/assessment.svg" alt="assessment" />
            </div>
            <div class="cd__model-text">
              {{
                getSelectedCourse.sections?.length > 1
                  ? $t("course.tooltip.assessments")
                  : $t("course.overview.assessment")
              }}
            </div>
          </div> -->
          <div class="d-flex" v-if="getSelectedCourse.effort_time">
            <div class="cd__model-icon">
              <img
                src="@/assets/images/landing/course/info/time.svg"
                alt="effort"
              />
            </div>
            <div class="cd__model-text">
              {{
                $t("course.x_modules_hours", {
                  s: getSelectedCourse.effort_time
                })
              }}
            </div>
          </div>
          <div class="d-flex" v-if="getSelectedCourse.credentials">
            <div class="cd__model-icon">
              <img
                src="@/assets/images/landing/course/info/awards.svg"
                alt="awards"
              />
            </div>
            <div class="cd__model-text">
              {{ getSelectedCourse.credentials.length }}
              {{ $t("general.awards") }}
            </div>
          </div>
          <div class="d-flex">
            <div class="cd__model-icon">
              <img
                src="@/assets/images/landing/course/info/language.svg"
                alt="translate"
              />
            </div>
            <div class="cd__model-text">
              {{
                getSelectedCourse.language
                  ? $t(`data.language.${getSelectedCourse.language}`)
                  : $t(`data.language.en`)
              }}
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-center">
          <Button
            variant="outline-primary"
            @click="$event => closeModal('course-details-modal')"
          >
            {{ $t("general.dismiss") }}
          </Button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getSelectedCourse"])
  },
  methods: {
    getAwards() {
      return this.getSelectedCourse.credentials?.length;
    },
    closeModal(id) {
      if (document.getElementById("cd-footer-section")) {
        document.getElementById("cd-footer-section").style.zIndex = "9999";
      }
      this.$bvModal.hide(id);
    },
    enrollSuccessModalClose(id) {
      if (
        this.getSelectedCourse.current_server_time >=
        this.getSelectedCourse.course_starts_at
      ) {
        this.$router.push({
          name: "Self Paced",
          params: { id: this.$route.params.id }
        });
      } else {
        this.$bvModal.hide(id);
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../../scss/course-details/modals";
</style>
